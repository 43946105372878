import { createReducer, on } from "@ngrx/store";
import { IAdvancedTableData, IDocument } from "src/app/@shared/components/advanced-table/advanced-table.interface";
import { IResponseApi } from "src/app/interfaces/IApi";
import * as InstancesActions from './instances.actions';
import { Instance, InstanceCreate } from "src/app/@core/services/api/interfaces/api-instance.interface";
import { InstanceStatus } from "src/app/@core/enums/instance.enums";
import { Option } from "src/app/@core/interfaces/generic.interfaces";
export interface InstancesState {
    instances: any;
    error: IResponseApi<any> | null;
    instancesList: IResponseApi<Option[]> | null
    instanceSelected: string | null,
    modelSelected:string| null,
    instanceById:string,
    status: 'idle' | 'loading' | 'success' | 'error' | 'created' | 'updated' | 'deleted';
    isUpdating: boolean;
    ModelUpdateError: any;

}

export const initialState: InstancesState = {
    instances: null,
    error: null,
    instancesList: null,
    instanceSelected: null,
    modelSelected:null,
    ModelUpdateError: null,
    status: 'idle',
    isUpdating: false,
    instanceById:"",
};

export const instancesReducer = createReducer(
    initialState,
    on(InstancesActions.resetAll, (state) => ({
        ...state=initialState,
      })),
    on(InstancesActions.sendSuccess, (state, { instance }) => ({
        ...state,
        instances: [...state.instances, instance],
    })),
    on(InstancesActions.sendFailure, (state, { error }) => ({
        ...state,
        error: error,
    })),
    on(InstancesActions.requestInstancesSuccess, (state, { instances }) => ({
        ...state,
        instances: instances,
    })),
    on(InstancesActions.requestInstancesError, (state, { error }) => ({
        ...state,
        error: error,
    })),
    on(InstancesActions.requestInstancesListSuccess, (state, { instances }) => ({
        ...state,
        instancesList: instances,
    })),
    on(InstancesActions.setParametersSelected, (state, { instance , model}) => ({
        ...state,
        instanceSelected: instance,
        modelSelected:model,
    })),

    on(InstancesActions.updateModel, (state) => {
        return {
            ...state,
            isUpdating: true,
            ModelUpdateError: null
        };
    }),
    
    on(InstancesActions.updateModelSucces, (state, { model }) => {
        const newState = {
            ...state,
            modelSelected: model,
            isUpdating: false,
            ModelUpdateError: null,
            instancesList: state.instancesList ? {
                ...state.instancesList,
                data: state.instancesList.data.map(option => 
                    option.name === state.instanceSelected
                        ? { ...option, model: model }
                        : option
                )
            } : null,
        };
        return newState;
    }),
    

    on(InstancesActions.updateModelFailure, (state, { ModelUpdateError }) => ({
        ...state,
        isUpdating: false,
        ModelUpdateError
    })),

    on(InstancesActions.requestInstanceSuccess, (state, { instance }) => ({
        ...state,
        instanceById: instance,
    }))
)

