import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ChatbotState } from "./chatbot.reducer";

export const selectChatbotState = createFeatureSelector<ChatbotState>('chatbot');

export const selectError = createSelector(
  selectChatbotState, (state: ChatbotState) => state.error
);

export const getSend = createSelector(
  selectChatbotState, (state: ChatbotState) => state.message
);

/* export const getChats = createSelector(
  selectChatbotState, (state: ChatbotState) => state.chats
); */

export const getChatIdSelected = createSelector(
  selectChatbotState, (state: ChatbotState) => state.chatIdSelected
)


export const getChatsSideBar = createSelector(
  selectChatbotState, (state: ChatbotState) => state.chatsSideBar
)

export const getChat = createSelector(
  selectChatbotState, (state: ChatbotState) => state.chat
)


export const formIsVisible = createSelector(
  selectChatbotState, (state: ChatbotState) => state.formIsVisible
)