
<!-- To do see here: https://mdbootstrap.com/docs/angular/navigation/sidenav/ -->
 <!--Advice: See id togler, trova il percorso che usa nel componente:: 2. Side navigation with a mode transition -->

 <mdb-sidenav-layout>
  <mdb-sidenav #sidenav="mdbSidenav" [mode]="mode" [position]="'fixed'" [hidden]="hidden">
    <div class="dashboard-sidenav__wrapper bg-primary d-flex flex-column vh-100 overflow-x-hidden position-relative">
      <!-- Logo -->
      <div class="vh-10 py-1 row justify-content-center align-items-center">
        <figure class="col-8 d-flex justify-content-start">
          <img src="../../../../assets/logo.svg" class="w-100" alt="logo">
        </figure>
      </div>
      <hr class="m-0" />
  
      <mdb-sidenav-item>
        <a class="sidenav-link d-flex align-items-center" routerLink="colossus/chat/new" routerLinkActive="active">
          <i class="far fa-comments me-3 text-light"></i>
          <span class="text-light fs-5"><strong>New chat</strong></span>
        </a>
      </mdb-sidenav-item>
      <!-- Menu -->
      <ul class="sidenav-menu bg-primary dashboard-sidenav__wrapper__menu list-unstyled">
        <ng-container>
          <!-- New Chat -->
  
          <!-- Chat List -->
          <ng-container *ngFor="let chat of (chatSideBar$ | async) as chats; let i = index">
            <div *ngIf="i === 0 || chat.dateKey !== chats[i-1].dateKey" 
                 class="ms-4 mt-3 align-items-center text-light fw-bold" >
              {{ chat.dateKey }}
            </div>
            <mdb-sidenav-item>
              <a class="sidenav-link text-light d-flex align-items-center"
                 [routerLink]="'colossus/chat/' + chat._id"
                 routerLinkActive="active"
                 (dblclick)="startEditing(chat)">
                <span *ngIf="!chat.isEditing" class="fw-normal text-truncate">
                  {{ chat.newName ? chat.newName : chat.title }}
                </span>
                <input 
                  mdbInput 
                  type="text" 
                  *ngIf="chat.isEditing" 
                  [value]="chat.newName!" 
                  (input)="onInputChange($event, chat)" 
                  (blur)="finishEditing(chat)" 
                  (keyup.enter)="finishEditing(chat)" 
                  (keyup.escape)="cancelEditing(chat)" 
                  [id]="'edit-input-' + chat._id" 
                  class="form-control">
              </a>
            </mdb-sidenav-item>
          </ng-container>
        </ng-container>
      </ul>
      <ng-container >
        <div  class="w-100 d-block d-lg-none position-absolute bottom-0 bg-primary z-index-up">
          <hr class="m-0 ">
          <ul class="ms-3 navbar-nav flex-row justify-content-center text-light ms-auto">
            <li *roles="[roleEnum.ADMIN]" class="nav-item ms-3 ms-lg-1">
              <a class="nav-link" [routerLink]='paths.MANAGEMENT' routerLinkActive="active" href="#">
                <i class="fas fa-users-gear fa-lg"></i>
              </a>
            </li>
            <li class="nav-item ms-3 mx-lg-1">
              <a class="nav-link" [routerLink]='paths.MY_PROFILE' routerLinkActive="active" href="#">
                <i class="fas fa-user fa-lg"></i>
              </a>
            </li>
            <li class="nav-item ms-3 ms-lg-1">
              <a class="nav-link" href="#" (click)="logout()">
                <span><i class="fas fa-sign-out-alt fa-lg"></i></span>
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
  </mdb-sidenav>
  <mdb-sidenav-content #sidenavContent class="overflow-x-hidden">
    <!-- Navbar -->
    <nav class=" navbar navbar-expand-lg navbar-light  bg-light NavVh ">
      <div class="container-fluid"  *ngIf="(parameters | async)?.instance; else noParametersNav">
          <!-- model and Instances in Desktop Window -->
          <div class="d-none d-lg-flex ">
              <div class="d-flex  align-items-center justify-content-center parameters mx-3">
                  <div class="fw-bolder mx-1 ">AI Model: </div>
                  <form [formGroup]="formUpdateModel" class=" z-index-up">
                    <mdb-form-control>
                      <mdb-select formControlName="model" class="w-100">
                        <mdb-option (click)="modelChange()" value="gpt-4o-2024-08-06">
                          GPT 4o-2024-08-06
                        </mdb-option>
                        <mdb-option (click)="modelChange()" value="gpt-4o-mini">
                          GPT 4o-mini
                        </mdb-option>
                      </mdb-select>
                    </mdb-form-control>
                  </form>
              </div>
              <div class="d-flex   align-items-center justify-content-center parameters ">
                <div class="fw-bolder me-2">Instance:</div>
                <span>{{ (parameters | async)?.instance }}</span>
              </div>
          </div>

          <!-- model and Instances in Mobile Window -->
            <ul class="navbar-nav d-lg-none d-flex flex-row justify-content-between mb-2 mb-lg-0 w-100  px-0 ">
              <li class="nav-item">
                <div class="   d-flex align-items-center d-block d-lg-none text-center ms-3 me-3 mx-lg-1 overflow-y-hidden" 
                aria-controls="#sidenav-1"
                aria-haspopup="true" 
                (click)="sidenav.toggle()">
                <i class="fas fa-bars fs-4"></i>
              </div>
              </li>
              <li class="nav-item">
                <div class="  d-block d-lg-none ">
                  <div
                    class=""
                    type="button"
                    (click)="openParameters()"
                  >
                  <i class="fa-solid fa-gears fs-4"></i>
                  </div>
              </div>
              </li>
            </ul>

            <!-- items in desktop view -->
            <div class=" d-none d-lg-block justify-content-end p-0 align-items-start  mb-2 mb-lg-0">
              <!-- My-profile-LogOut BTNS -->
              <ul class="navbar-nav flex-row justify-content-end  mb-2 mb-lg-0">
                <li *roles="[roleEnum.ADMIN]" class="nav-item ms-3 ms-lg-1">
                  <a class="nav-link" [routerLink]='paths.MANAGEMENT' routerLinkActive="active" href="#">
                    <i class="fas fa-users-gear fa-lg"></i>
                  </a>
                </li>
                <li class="nav-item ms-3 mx-lg-1">
                  <a class="nav-link" [routerLink]='paths.MY_PROFILE' routerLinkActive="active" href="#">
                    <i class="fas fa-user fa-lg"></i>
                  </a>
                </li>
                <li class="nav-item ms-3 ms-lg-1">
                  <a class="nav-link" href="#" (click)="logout()">
                    <span><i class="fas fa-sign-out-alt fa-lg"></i></span>
                  </a>
                </li>
                <li>
                  <div
                  class=" d-block d-md-none text-center mx-3 mx-lg-1"
                  aria-controls="#sidenav-1"
                  aria-haspopup="true"
                  (click)="sidenav.toggle()"
                >
                  <i class="fas fa-bars fa-lg"></i>
                </div>
                </li>
              </ul>
            </div>
      </div>

      <ng-template #noParametersNav>
        <div class="container-fluid">
          <!--NavBar in New chat or Instances-MyProfile -->
          <ul class="navbar-nav flex-row justify-content-lg-end mb-2 mb-lg-0 w-100">
            <li *roles="[roleEnum.ADMIN]" class="d-none d-lg-block nav-item ms-3 ms-lg-1 ">
              <a class="nav-link" [routerLink]='paths.MANAGEMENT' routerLinkActive="active" href="#">
                <i class="fas fa-users-gear fa-lg"></i>
              </a>
            </li>
            <li class="nav-item ms-3 mx-lg-1 d-none d-lg-block">
              <a class="nav-link" [routerLink]='paths.MY_PROFILE' routerLinkActive="active" href="#">
                <i class="fas fa-user fa-lg"></i>
              </a>
            </li>
            <li class="nav-item ms-3 ms-lg-1 d-none d-lg-block">
              <a class="nav-link" href="#" (click)="logout()">
                <span><i class="fas fa-sign-out-alt fa-lg"></i></span>
              </a>
            </li>
            <li>
              <div
              class=" d-block d-lg-none text-center mx-3 mx-lg-1"
              aria-controls="#sidenav-1"
              aria-haspopup="true"
              (click)="sidenav.toggle()"
            >
              <i class="fas fa-bars fs-4"></i>
            </div>
            </li>
          </ul>
        </div>
      </ng-template>
    </nav>
    <!-- Content -->
    <div class="container">
      <ng-content></ng-content>
    </div>
  </mdb-sidenav-content>
</mdb-sidenav-layout>