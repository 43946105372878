import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
    name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {
    transform(value: string): string {
        const now = new Date();
        const date = new Date(value);
        const diffInMs = now.getTime() - date.getTime();
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        if (diffInDays === 0) {
            return 'Today';
        } else if (diffInDays === 1) {
            return 'Yesterday';
        } else if (diffInDays < 7) {
            return 'Last 7 days';
        } else if (diffInDays < 30) {
            return 'Last 30 days';
        } else {
            const monthYear = formatDate(date, 'MMMM yyyy', 'it-IT');
            return monthYear;
        }
    }
}
