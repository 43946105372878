import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import { selectError} from "./stores/auth/auth.selector";
import { Subject} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  title = 'knowledge-based-chatbot';
  error$ = this.store.select(selectError);
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private store:Store,
  ) {}

  ngOnInit(): void {
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
