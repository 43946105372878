import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { userGetAllApi, userGetByIdApi } from "src/app/@shared/constants/api-url.constant";
import { environment } from "src/environments/environment";
import { IUserFormData } from "../../interfaces/user.interface";
import { GetResponse, GetTableData, ResponseTableData } from "./interfaces/api-utils.interface";
import { IAdvancedTableData, IAdvancedTableField, IDocument } from "src/app/@shared/components/advanced-table/advanced-table.interface";
import { Instance, InstanceCreate } from "./interfaces/api-instance.interface";
import { IResponseApi } from "src/app/interfaces/IApi";
import { Option } from "../../interfaces/generic.interfaces";


@Injectable({
    providedIn: 'root'
})
export class InstanceService {
    headers = new HttpHeaders()
    constructor(private http: HttpClient) {
    }



    getAll(req: GetTableData): Observable<any> {
        return this.http.post(environment.url_api + '/instance/getInstances', req, { headers: this.headers });
    }

    getList(): Observable<IResponseApi<Option[]>> {
        return this.http.get<IResponseApi<Option[]>>(environment.url_api + '/instance/list', { headers: this.headers });
    }

    deleteInstance(id: string) {
        return this.http.delete(environment.url_api + '/instance/' + id, { headers: this.headers });

    }

    getInstanceById(id: string): Observable<IResponseApi<Instance>> {
        return this.http.get<IResponseApi<Instance>>(environment.url_api + '/instance/' + id, { headers: this.headers });
    }

    createInstance() {

    }

    send(instance: InstanceCreate): Observable<any> {
        return this.http.post<any>(environment.url_api + '/instance', instance, { headers: this.headers })
    }

    /* 
        getById(id: string): Observable<any> {
            return this.http.get(environment.url_api + userGetByIdApi + id);
        }
    
        updateUserData(id: string, data: IUserFormData): Observable<any> {
            return this.http.get(environment.url_api + userGetByIdApi + id);
        }
    
        resetPassword(email: string): Observable<any> {
            return this.http.get(environment.url_api + userGetByIdApi + email);
        } */
}

