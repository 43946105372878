import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AlertService } from '../@shared/alert/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private unauthorizedAlertShown: boolean = false; // Variabile per tracciare se l'alert è già stato mostrato

    constructor(private alertService: AlertService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // Controlla se l'errore è di tipo HttpErrorResponse
                if (error instanceof HttpErrorResponse) {
                    // Mostra l'errore tramite il servizio di alert solo una volta per "Unauthorized"
                    if (error.error.message === "Unauthorised" && !this.unauthorizedAlertShown) {
                        this.unauthorizedAlertShown = true; // Imposta a true dopo aver mostrato l'alert
                        this.alertService.openErrorAlert(!!error.error.message ? error.error.message : "errore");
                        setTimeout(()=>{ this.unauthorizedAlertShown = false;},1.5 * 1000)
                    }
                    else if(error.error.message !== "Unauthorised"){
                        this.alertService.openErrorAlert(!!error.error.message ? error.error.message : "errore");
                    }
                }
                return throwError(() => error);
            })
        );
    }
}
