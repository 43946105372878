import { createReducer, on } from '@ngrx/store';
import { IResponseApi } from '../../interfaces/IApi';
import * as ChatbotActions from './chatbot.actions';
import {
  IChat,
  IChatForSidebar,
  IMessage,
  IResponseChat,
} from '../../interfaces/IChat';
import { loginFailure } from '../auth/auth.actions';

export interface ChatbotState {
  chat: IChat;
  chatIdSelected: string;
  message: IMessage | null;
  error: IResponseApi<any> | null;
  chatsSideBar: IChatForSidebar[];
  formIsVisible: boolean;

}

export const initialState: ChatbotState = {
  chat: {
    _id: '',
    messages: [],
  },
  chatIdSelected: 'new',
  message: null,
  error: null,
  chatsSideBar: [],
  formIsVisible: true,
};

export const chatbotReducer = createReducer(
  initialState,

  on(ChatbotActions.formIsVisiblee, (state, { formIsVisible }) => ({
    ...state,
    formIsVisible: formIsVisible,
  })),
  on(ChatbotActions.sendSuccess, (state, { chat }) => ({
    ...state,
    chat: mergeResponseInChat(state, chat),
  })),
  on(ChatbotActions.sendFailure, (state, { error }) => ({
    ...state,
    error:error,
  })),
  
  on(ChatbotActions.sendUserMessage, (state, { message, sender }) =>
    senderMessage(state, message, sender)
  ),
  on(ChatbotActions.updateChatId, (state, { id }) => ({
    ...state,
    chatIdSelected: id,
  })),

  on(ChatbotActions.updateChatsSideBar, (state, { chats }) => ({
    ...state,
    chatsSideBar: chats,
  })),


  on(ChatbotActions.renameChatTitleFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  on(ChatbotActions.renameChatTitleSuccess, (state, { chatId, title }) =>
    renameChat(state, chatId)
  ),

  on(ChatbotActions.requestChatSuccess, (state, { chat }) =>
    mergeChat(state, chat)
  ),
  on(ChatbotActions.requestChatFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  on(ChatbotActions.requestChatsSuccess, (state, { chats }) => ({
    ...state,
    chats,
  })),
  on(ChatbotActions.requestChatsFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  on(ChatbotActions.requestChatsSideBarSuccess, (state, { chatsSideBar }) =>
    takeChats(state, chatsSideBar)
  ),
  on(ChatbotActions.requestChatsSideBarFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),

  on(ChatbotActions.resendSuccess, (state, { chat }) => ({
    ...state,
    chats: mergeResponseInChat(state, chat),
  })),
  on(ChatbotActions.removeLastMessage, (state, { chatId }) => ({
    ...state,
    chats: removeLastMessage(state),
  })),
  on(ChatbotActions.resetStatus, (state, { chatId }) => ({
    ...state,
    chat: resetStatus(state),
  })),
  on(ChatbotActions.resendUserMessage, (state) => ({
    ...state,
    chats: setResendMessage(state),
  }))
);

function mergeResponseInChat(state: ChatbotState, chat: IResponseChat) {
  const index = state.chatsSideBar.findIndex(
    (c) => c._id === state.chatIdSelected
  );
  const _chat = state.chat;
  if (index !== -1) {
    const messages = _chat.messages;
    messages[messages.length - 1].message = chat.answer;
    messages[messages.length - 1].loading = false;
    messages[messages.length - 1].sources = chat.sources;
    _chat.messages = messages;
  }
  return _chat;
}

function setResendMessage(state: ChatbotState) {
  const _chat = state.chat;
  const indexMessage = _chat.messages.length - 1;
  const message = _chat.messages[indexMessage];
  message.message = '...';
  message.sender = 'bot';
  message.created_at = new Date().toISOString();
  message.loading = false;
  _chat.messages[indexMessage] = message;
  return _chat;
}

function removeLastMessage(state: ChatbotState) {
  const _chat = state.chat;
  const messagesChanged = _chat.messages.slice(0, -1);
  _chat.messages = messagesChanged;
  return _chat;
}

function resetStatus(state: ChatbotState) {

  let _chat = state.chat;
  _chat = {
    _id: state.chat._id,
    messages: [],
    gptModel: _chat.gptModel,
    instance: _chat.instance,
  }
  return _chat;
}



function mergeChat(state: ChatbotState, chat: IChat) {
  const _chats = [...state.chatsSideBar];
  if (state.chatIdSelected === 'new') {
    state.chat = chat;

    state.chatIdSelected = chat._id

    return { ...state, chats: _chats };
  }

  state.chatIdSelected = chat._id
  const index = _chats.findIndex((c) => c._id === state.chatIdSelected);
  if (index === -1) {
    state.chatIdSelected = chat._id
    return { ...state, chats: [..._chats, chat] }
  }
  state.chatIdSelected = chat._id
  state.chat = chat;
  return { ...state, chats: _chats };
}


function takeChats(state: ChatbotState, chatsSideBar: any) {
  state.chatsSideBar = chatsSideBar;
  return { ...state, chatsSideBar };
}

function senderMessage(
  state: ChatbotState,
  message: string,
  sender: 'user' | 'bot'
): ChatbotState {
  const updatedChat = { ...state.chat };
  const updatedChats = [...state.chatsSideBar];

  const chatIndex = updatedChats.findIndex((c) => c._id === state.chatIdSelected);

  const newMessage = {
    message: sender === 'bot' ? '...' : message,
    sender,
    created_at: new Date().toISOString(),
    loading: sender === 'user',
  };

  updatedChat.messages = [...updatedChat.messages, newMessage];

  if (chatIndex === -1 && sender === 'user' && !updatedChats.some(c => c._id === updatedChat._id)) {
    // This is a new chat and it doesn't exist in the sidebar yet
    updatedChats.unshift({
      _id: updatedChat._id,
      title: message, // Use the first user message as the title
      instance: updatedChat.instance!,
      dateKey: "today",
    });
  } else if (chatIndex !== -1) {
    // Update existing chat in the sidebar
    updatedChats[chatIndex] = {
      ...updatedChats[chatIndex],
      title: updatedChat.messages[0].message, // Update title if needed
    };
  }

  return {
    ...state,
    chat: updatedChat,
    chatsSideBar: updatedChats
  };
}

function renameChat(state: ChatbotState, title: string) {
  const _chats = [...state.chatsSideBar];
  const index = _chats.findIndex((c) => c._id === state.chatIdSelected);

  if (index !== -1) {
    _chats[index] = { ..._chats[index], title };
  }
  
  return { ...state, chatsSideBar: _chats };
}




