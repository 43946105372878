import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AlertService } from "src/app/@shared/alert/alert.service";
import { IResponseApi } from "src/app/interfaces/IApi";
import { login } from "src/app/stores/auth/auth.actions";
import { isLoggedIn, selectError } from "src/app/stores/auth/auth.selector";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('4892301d', [Validators.required, Validators.minLength(6)])
  })

  errors: { [key: string]: boolean } = this.form.errors || {}
  error$: Observable<IResponseApi<any> | null>;
  isLoggedIn$: Observable<boolean>;
  loading: boolean = false;

  constructor(private store: Store, private router: Router, private alertService: AlertService) {
    this.isLoggedIn$ = this.store.select(isLoggedIn);
  }

  ngOnInit() {
    this.error$=this.store.select(selectError)
    this.error$.subscribe(err => {
      this.loading = false;
      this.form.enable();
      })
     this.isLoggedIn$.subscribe(isLoggedIn => {
       this.loading = false;
       this.form.enable();
       if (isLoggedIn) {
        this.router.navigate(['/dashboard'])
      }
    }
    )
  }

  login() {
    if (this.form.invalid) {
      this.alertService.openErrorAlert('Enter email and password');
      return;
    }
    this.form.disable();
    this.loading = true;
    this.store.dispatch(login({ username: this.form.value.email, password: this.form.value.password }))
  }
}
