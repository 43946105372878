import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from "./@core/core.module";
import { Mdb5Module } from "./@core/modules/mdb5.module";
import { ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authReducer } from "./stores/auth/auth.reducer";
import { AuthEffects } from "./stores/auth/auth.effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./@core/interceptors/auth.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { chatbotReducer } from "./stores/chatbot/chatbot.reducer";
import { ChatbotEffects } from "./stores/chatbot/chatbot.effects";
import { LoginComponent } from './features/login/login.component';
import { HistoryService } from './@core/services/history.service';
import { instancesReducer } from './stores/instances/instances.reducer';
import { InstancesEffects } from './stores/instances/instances.effect';
import { ConfirmPasswordComponent } from './features/confirm-password/confirm-password.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ConfirmPasswordComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        CoreModule,
        Mdb5Module,
        BrowserAnimationsModule,
        StoreModule.forRoot({ auth: authReducer, chatbot: chatbotReducer, instances: instancesReducer }, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        EffectsModule.forRoot([AuthEffects, ChatbotEffects, InstancesEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: !environment.production, // Restrict extension to log-only mode
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        HistoryService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
