<div class="container">
  <div class="d-flex justify-content-center vh-100 align-items-center">
    <div style="width: 400px;">
      <form [formGroup]="form" (submit)="login()">
        <div class="card login-card">
          <div class="card-header login-card__header text-center text-white bg-primary fs-3">Login</div>
          <div class="card-body">
            <div class="mb-3 pb-1">
              <mdb-form-control>
                <input mdbInput required type="email" id="email" formControlName="email" class="form-control"/>
                <label mdbLabel class="form-label" for="email">Email</label>
                <mdb-error *ngIf="errors['email']">
                  Invalid email
                </mdb-error>
              </mdb-form-control>
            </div>
            <div>
              <mdb-form-control>
                <input mdbInput required type="password" id="password" formControlName="password" class="form-control my-custom-border-color"/>
                <label mdbLabel class="form-label" for="password">Password</label>
                <mdb-error *ngIf="errors['password']">
                  The password must be at least 6 characters long
                </mdb-error>
              </mdb-form-control>
            </div>
          </div>
          <div class="card-footer d-flex align-items-center">
              <span class="me-auto" routerLink="send-email">
                <a href="/send-email" >Forgot password</a></span>

            <button type="submit" class="btn btn-primary btn-sm" loadingBtn [loadingState]="loading">Log in</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
