import { Component } from '@angular/core';
import {MdbModalRef} from "mdb-angular-ui-kit/modal";

import { Store } from '@ngrx/store';
import { setStatusReset } from 'src/app/stores/auth/auth.actions';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {
  title: string | null = null;
  body: string | null = null;
  actionsLabel?:{
    cancel: string;
    confirm: string;
  };

  constructor(
    private store:Store,
    private modalRef: MdbModalRef<ConfirmModalComponent>) { }

  close(status?: boolean){
    if(status===true){
      this.store.dispatch(setStatusReset({staus:true}))
    }
    this.modalRef.close(status);
  }
}
