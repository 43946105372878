<div class="d-flex justify-content-center align-items-center vh-100">
    <div class="col-md-6 col-lg-4">
        <div class="h-25 w-100 header">
            <h4 class="p-3 c-light m-0 text-center">Confirm your registration</h4>
        </div>
        <form [formGroup]="form" class="d-flex flex-column p-4 rounded shadow bg-white" (submit)="send()">
            
            <div class="form-outline mb-3">
                <input 
                    type="password" 
                    class="form-control" 
                    formControlName="password" 
                />
                @if(!(form.value.password)){
                    <label class="form-label">Password</label>
                }
            </div>

            <div class="form-outline mb-3">
                <input 
                    type="password" 
                    class="form-control" 
                    formControlName="confirmPassword" 
                />
                @if(!(form.value.confirmPassword)){
                    <label class="form-label">Confirm password</label>
                }
            </div>

            <button 
                type="button" 
                (click)="send()" 
                class="btn btn-primary btn-block mt-3"
            >
                Sign in
            </button>

            <!-- Messaggio di errore di validazione password -->
            <div *ngIf="form.dirty && form.invalid" class="alert alert-danger mt-3 text-center">
                The passwords are invalid.
            </div>
            <div *ngIf="validate === false" class="alert alert-danger mt-2 text-center">
                The passwords do not match.
            </div>
        </form>
    </div>
</div>
