import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import {error, IResponseApi} from "../../interfaces/IApi";
import {IUser} from "../../@core/services/api/interfaces/api-auth.interface";

export interface AuthState {
  user: IUser | null | undefined
  error: IResponseApi<any> | null;
  modalStatus:boolean;
}

export const initialState: AuthState = {
  user: null,
  error: null,
  modalStatus:false,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, { user }) => ({ ...state, user, error: null })),
  on(AuthActions.loginFailure, (state, { error }) => ({ ...state, error, user: null })),
  on(AuthActions.logout, state => ({ ...state, user: null, error: null })),
  on(AuthActions.getUserDetailsSuccess, (state, { userDetails }) => ({ ...state, user: userDetails, error: null })),
  on(AuthActions.getUserDetailsFailure, (state) => ({ ...state, user: undefined })),
  on(AuthActions.setStatusReset, (state, { staus }) => ({ ...state, modalStatus:staus}))
  
);
