import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../@shared/alert/alert.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private alertService: AlertService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // Controlla se l'errore è di tipo HttpErrorResponse
                if (error instanceof HttpErrorResponse) {
                    // Mostra l'errore tramite il servizio di alert
                    this.alertService.openErrorAlert(!!error.error.message ? error.error.message : "errore");
                }
                return throwError(() => error);
            })
        );
    }
}