import { Injectable, model } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as ChatbotActions from './chatbot.actions';
import * as InstancesAction from '../instances/instances.actions'
import { ApiChatbotService } from "../../@core/services/api/api-chatbot.service";
import { catchError, map, mergeMap, take, withLatestFrom } from "rxjs/operators";
import { of, tap } from "rxjs";
import { Store } from "@ngrx/store";
import { getChat, getChatIdSelected } from "./chatbot.selector";
import { getInstancesSelected } from "../instances/instances.selector";
import { AlertService } from "src/app/@shared/alert/alert.service";

@Injectable()
export class ChatbotEffects {
    send$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.send),
        tap((action) => {

            this.store.dispatch(ChatbotActions.sendUserMessage({ message: action.query, sender: 'user' }))
            this.store.dispatch(ChatbotActions.sendUserMessage({ message: '...', sender: 'bot' }))

        }),
        mergeMap((action) => this.chatbotservice.send({ instance: action.instance, query: action.query, chatId: action.chatId, model: action.model })
            .pipe(
                map(response => {
                    if (action.chatId === 'new') {
                        this.store.dispatch(ChatbotActions.requestChat({ chatId: response.data.chatId }))
                    }
                    return ChatbotActions.sendSuccess({ chat: response.data })
                }),
            catchError(error => of(ChatbotActions.sendFailure({ error: error })))
            )
        )
    ));

    sendFailure$ = createEffect(() =>
        this.actions$.pipe(
          take(1),
          ofType(ChatbotActions.sendFailure),
          map(action => {
            this.store.select(getChat).subscribe(res=>{
            if (action.error.error?.message === 'GPT-4o messages limit reached' && res._id !== "new" ) {
                    this.store.dispatch(InstancesAction.updateModel({ chatId: res._id, model: 'gpt-4o-mini' }));
                }
            })
          })
        ), 
        { dispatch: false } 
      );

    get$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.requestChat),
        mergeMap((action) => this.chatbotservice.get({ chatId: action.chatId! })
            .pipe(
                map(response => {
                    return ChatbotActions.requestChatSuccess({ chat: response.data })

                }),
                catchError(error => of(ChatbotActions.requestChatFailure({ error: error.error })))
            )
        )
    ));

    getChatsSideBar$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.requestChatsSideBar),
        mergeMap(() => this.chatbotservice.getChatsSideBar()
            .pipe(
                map(response => {
                    return ChatbotActions.requestChatsSideBarSuccess({ chatsSideBar: response.data })
                }),
                catchError(error => of(ChatbotActions.requestChatsSideBarFailure({ error: error.error })))
            )
        )
    ));


    renameChatTitle$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.renameChatTitle),
        mergeMap((action) => this.chatbotservice.renameChatTitle(action.chatId, action.title)
            .pipe(
                map(response => {
                    return ChatbotActions.renameChatTitleSuccess({ chatId: action.chatId, title: action.title })
                }),
                catchError(error => of(ChatbotActions.renameChatTitleFailure({ error: error })))
            )
        )
    ));

    resendMessage$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.resendChat),
        withLatestFrom(
            this.store.select(getChatIdSelected),
            this.store.select(getInstancesSelected),
        ),
        tap(([action, chatId, instance]) => {
            this.store.dispatch(ChatbotActions.resendUserMessage())
        }),
        mergeMap(([action, chatId, instance]) => this.chatbotservice.resendChat({
            query: action.message, chatId: chatId, instance: instance?.instance!, model: instance?.model!
        })
            .pipe(map(response => {
                return ChatbotActions.resendSuccess({ chat: response.data })
            }),
                catchError(error => of(ChatbotActions.sendFailure({ error: error.error })))
            )
        )
    ));
    constructor(private alert: AlertService,private actions$: Actions, private chatbotservice: ApiChatbotService, private store: Store) {
    }
}
