import { Component, OnInit, signal, Signal } from '@angular/core';
import { Store } from "@ngrx/store";
import { selectUser } from "../../../stores/auth/auth.selector";
import { Router } from "@angular/router";
import { formIsVisible, getChatIdSelected, getChatsSideBar } from "../../../stores/chatbot/chatbot.selector";
import { renameChatTitle, requestChat, requestChats, requestChatsSideBar, resetStatus } from "../../../stores/chatbot/chatbot.actions";
import { EPaths } from '../../enums/path.enums';
import { ERole } from '../../enums/role.enums';
import { IChat, IChatForSidebar, IChatPatameters } from 'src/app/interfaces/IChat';
import { RelativeDatePipe } from '../../pipes/relative-date.pipe';
import { EMPTY, map, noop, Observable, of, switchMap, take, tap } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { getInstancesList, getInstancesSelected as getInstancesSelected } from 'src/app/stores/instances/instances.selector';
import { logout } from 'src/app/stores/auth/auth.actions';
import { requestInstancesList, updateModel } from 'src/app/stores/instances/instances.actions';
import { Option } from "src/app/@core/interfaces/generic.interfaces";
import { AlertService } from 'src/app/@shared/alert/alert.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  providers: [RelativeDatePipe]
})
export class SidebarComponent implements OnInit {
  user$ = this.store.select(selectUser)
  type: string | null = null;
  showExpand: boolean;
  paths = EPaths;
  roleEnum = ERole;
  chatSideBar$ = this.store.select(getChatsSideBar)
  valid: Observable<boolean> = this.store.select(formIsVisible)
  error: any = "";
  instanceOptions: Option[];
  parameters: Observable<IChatPatameters | null>;
  index: Observable<string>;

  formUpdateModel = new FormGroup({
    model: new FormControl("", Validators.required)
  })


  constructor(
    private store: Store,
    private router: Router,
    private alert: AlertService,
  ) {

    this.store.dispatch(requestChatsSideBar())
  }


  ngOnInit(): void {
    this.index = this.store.select(getChatIdSelected)
    this.store.dispatch(requestInstancesList())
    this.store.select(getInstancesList).pipe(
      tap((data) => {
        if (data) {
          this.instanceOptions = data!.data ?? []
        }
      })
    ).subscribe(noop)
    this.parameters = this.store.select(getInstancesSelected).pipe(

      tap(res => {
        this.formUpdateModel.patchValue({
          model: res?.model,
        })
      })
    )



  }

  modelChange() {
    this.store.select(getInstancesSelected).pipe(
      take(1),
      tap(inst => {
        if (inst && inst.model !== this.formUpdateModel.value.model) {
            this.store.select(getChatIdSelected).pipe(
            take(1),
            tap(chatId => {
              if (chatId) {
                this.store.dispatch(updateModel({
                  chatId: chatId,
                  model: this.formUpdateModel.value.model!
                }));
              }
            })
          );
        }
      })
    ).subscribe(() => {
      this.store.dispatch(requestChatsSideBar());
    });
  }

  /*   onSidenavHidden() {
      http://localhost:5575/dashboard/colossus/chat/66fbd4e9b51d0318000f9d93
      this.showExpand = true;
    }
  
    onSidenavShow() {
      this.showExpand = false;
    } */

  startEditing(chat: IChatForSidebar) {
    chat.isEditing = true;
    chat.newName = chat.title;
    setTimeout(() => {
      const input = document.getElementById('edit-input-' + chat._id) as HTMLInputElement;
      input?.focus();
    });
  }

  onInputChange(event: Event, chat: IChatForSidebar) {
    const target = event.target as HTMLInputElement;
    if (target) {
      chat.newName = target.value;
    }
  }

  reset(chat: IChatForSidebar) {
    this.store.dispatch(resetStatus({ chatId: chat._id ? chat._id : 'new' }))
  }

  finishEditing(chat: IChatForSidebar) {
    if (chat.newName && chat.newName.trim() !== '') {
      this.store.dispatch(renameChatTitle({ chatId: chat._id, title: chat.newName.trim() }))
    }
    chat.isEditing = false;
  }

  cancelEditing(chat: IChatForSidebar) {
    chat.isEditing = false;
  }

  logout() {
    localStorage.removeItem('token')
    this.router.navigate(['/login'])
  }
}


