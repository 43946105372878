import {Injectable} from '@angular/core';
import {MdbModalRef, MdbModalService} from 'mdb-angular-ui-kit/modal';
import { IModalConfig } from './modal.interface';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@Injectable({
	providedIn: 'root'
})
export class ModalService {
  private confirmModal!: MdbModalRef<ConfirmModalComponent>;

	constructor(private modalService: MdbModalService,) {
	}


  

  openConfirmModal(data: IModalConfig) {
    let sizeClass;
    switch (data.size) {
        case 'sm':
            sizeClass = 'modal-sm';
            break;
        case 'md':
            sizeClass = 'modal-md';
            break;
        default:
            sizeClass = 'modal-lg';
  }
    this.confirmModal = this.modalService.open(ConfirmModalComponent, {
      modalClass: 'modal-dialog-centered '+ sizeClass,
      ignoreBackdropClick: true,
      data: {title: data.title, body: data.body, actionsLabel: data.actionsLabel}
    });
    return this.confirmModal.onClose;
  }

}
