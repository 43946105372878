import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { take, switchMap, tap, EMPTY, noop, Observable } from 'rxjs';
import { IChatPatameters } from 'src/app/interfaces/IChat';
import { requestChatsSideBar } from 'src/app/stores/chatbot/chatbot.actions';
import { getChatIdSelected } from 'src/app/stores/chatbot/chatbot.selector';
import { requestInstancesList, updateModel } from 'src/app/stores/instances/instances.actions';
import { getInstancesList, getInstancesSelected } from 'src/app/stores/instances/instances.selector';
import { Option } from "src/app/@core/interfaces/generic.interfaces";

@Component({
  selector: 'chatbot-parameters-modal',
  templateUrl: './parameters-modal.component.html',
  styleUrl: './parameters-modal.component.scss'
})
export class ParametersModalComponent {
  error: any = "";
  parameters: Observable<IChatPatameters | null>;
  formUpdateModel = new FormGroup({
    model: new FormControl("", Validators.required)
  })
  instanceOptions: Option[];
  index: Observable<string>;
  constructor(
    public modalRef: MdbModalRef<ParametersModalComponent>,
    private store: Store,

  ) {}

  ngOnInit(): void {
    this.index = this.store.select(getChatIdSelected)
    this.store.dispatch(requestInstancesList())
    this.store.select(getInstancesList).pipe(
      tap((data) => {
        if (data) {
          this.instanceOptions = data!.data ?? []
        }
      })
    ).subscribe(noop)
    this.parameters = this.store.select(getInstancesSelected).pipe(

      tap(res => {
        this.formUpdateModel.patchValue({
          model: res?.model,
        })
      })
    )
  }

  modelChange() {
    this.store.select(getInstancesSelected).pipe(
      take(1),
      switchMap(inst => {
        if (inst && inst.model !== this.formUpdateModel.value.model) {
          return this.store.select(getChatIdSelected).pipe(
            take(1),
            tap(chatId => {
              if (chatId) {
                this.store.dispatch(updateModel({
                  chatId: chatId,
                  model: this.formUpdateModel.value.model!
                }));
              }
            })
          );
        }
        return EMPTY;
      })
    ).subscribe(() => {
      this.store.dispatch(requestChatsSideBar());
    });
  }
}
