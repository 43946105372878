import { createAction, props } from "@ngrx/store";
import { IChat, IChatForSidebar, IResponseChat } from "../../interfaces/IChat";
import { IResponseApi } from "src/app/interfaces/IApi";

export const send = createAction('[Chatbot] Send', props<{ instance: string, query: string, chatId: string , model:string }>());
export const sendSuccess = createAction('[Chatbot] Send Success', props<{ chat: IResponseChat }>());
export const sendFailure = createAction(
    '[Chatbot] Send Failure',
    props<{ error: IResponseApi<any> }>()
);

export const sendUserMessage = createAction('[Chatbot] Send User Message', props<{ message: string, sender: 'user' | 'bot' }>());

export const updateChatId = createAction('[Chatbot] Update ChatId', props<{ id: string }>())

export const formIsVisiblee = createAction('[Chatbot] Visible Form', props<{ formIsVisible: boolean }>())

export const requestChat = createAction('[Chatbot] Get', props<{ chatId: string }>());
export const requestChatSuccess = createAction('[Chatbot] Get Success', props<{ chat: IChat }>());
export const requestChatFailure = createAction('[Chatbot] Get Failure', props<{ error: any }>());

/* export const updateModel = createAction('[Chatbot] Update Model', props<{ chatId: string , model:string}>())
export const updateModelSucces = createAction('[Chatbot] Update Model Success', props<{  model: string }>());
export const updateModelFailure = createAction('[Chatbot] Update Model Failure', props<{ ModelUpdateError: any }>()); */
export const updateChatsSideBar = createAction('[Chat] Update Chats SideBar',props<{ chats: IChatForSidebar[] }>());

export const renameChatTitle = createAction('[Chatbot] Rename', props<{ chatId: string, title: string }>());
export const renameChatTitleSuccess = createAction('[Chatbot] Rename Success', props<{ chatId: string, title: string }>());
export const renameChatTitleFailure = createAction('[Chatbot] Rename Failure', props<{ error: any }>());

export const requestChats = createAction('[Chatbot] Get chats');
export const requestChatsSuccess = createAction('[Chatbot] Get chats Success', props<{ chats: IChat[] }>());
export const requestChatsFailure = createAction('[Chatbot] Get chats Failure', props<{ error: any }>());

export const requestChatsSideBar = createAction('[Chatbot] Get chats SideBar');
export const requestChatsSideBarSuccess = createAction('[Chatbot] Get chatsSideBar Success', props<{ chatsSideBar: IChatForSidebar[] }>());
export const requestChatsSideBarFailure = createAction('[Chatbot] Get chatsSideBar Failure', props<{ error: any }>());

export const resendChat=createAction('[Chatbot] Resend',props< {message:string} >());
export const resendSuccess = createAction('[Chatbot] Send Success', props<{ chat: IResponseChat }>());
export const removeLastMessage=createAction('[Chatbot removeLastMessage',props<{chatId: string}>())
export const resendUserMessage = createAction('[Chatbot] resend User Message');


export const resetStatus = createAction('[Reset] reset',props<{chatId: string}>())


