import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ApiAuthService } from 'src/app/@core/services/api/api-auth.service';
import { ApiChatbotService } from 'src/app/@core/services/api/api-chatbot.service';
import {AlertService} from "../../@shared/alert/alert.service";
import { ModalService } from 'src/app/@shared/modal/modal.service';
import { Store } from '@ngrx/store';
import { modalStatus } from 'src/app/stores/auth/auth.selector';
import { setStatusReset } from 'src/app/stores/auth/auth.actions';

@Component({
  selector: 'chatbot-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrl: './confirm-password.component.scss',
})
export class ConfirmPasswordComponent {
  token: string | null = null;
  validate: boolean;
  form = new FormGroup({
    password: new FormControl<string | undefined>(undefined,[
      Validators.required,
      Validators.minLength(6)
    ]),
    confirmPassword: new FormControl<string | undefined>(undefined,[
      Validators.required,
      Validators.minLength(6)
    ]),
  });

  constructor(
    private store:Store,
    private modal:ModalService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private api: ApiAuthService,
    private alertService: AlertService
  ) {
    this.token = this.activeRoute.snapshot.paramMap.get('token');
    if (!this.token) {
      this.router.navigate(['/login']);
    }
  }

  send() {
    if(this.form.value.password === this.form.value.confirmPassword){
      this.modal.openConfirmModal({body:"Are you sure to set the new password?",size:'md'})
      this.store.select(modalStatus).subscribe(modalState=>{    
        if(modalState===true){
          this.api.completeRegistration(this.form.value.password!, this.token!).subscribe({
            next: (res) => {
              this.form.reset();
              this.alertService.openSuccessAlert("Your new password is correctly registered");
              this.store.dispatch(setStatusReset({staus:false}))
              this.router.navigate(['/login']);
              
            },
          })
        }    
      })
      
    } else {
      this.alertService.openErrorAlert('Passwords do not match');
    }
  }
}
