import { createFeatureSelector, createSelector } from "@ngrx/store";
import { InstancesState } from "./instances.reducer";
import { IChatPatameters } from "src/app/interfaces/IChat";

export const selectInstanceState = createFeatureSelector<InstancesState>('instances');

export const getInstances = createSelector(
    selectInstanceState, (state: InstancesState) => state.instances ? state.instances : null
);
export const getInstancesList = createSelector(
    selectInstanceState, (state: InstancesState) => state.instancesList ? state.instancesList : null
);
export const getInstancesSelected = createSelector(
    selectInstanceState, (state: InstancesState) => state.modelSelected && state.instanceSelected ? { instance: state.instanceSelected, model: state.modelSelected } as IChatPatameters : null
);
export const modelUpdateError = createSelector(
    selectInstanceState, (state: InstancesState) => state.ModelUpdateError
)

export const instanceById = createSelector(
    selectInstanceState, (state: InstancesState) => state.instanceById
)

export const getIsUpdating = createSelector(
    selectInstanceState,
    (state: InstancesState) => {
        return state.isUpdating;
    }
);