
import { AfterViewInit, Component, NgZone, OnInit, signal, Signal, ViewChild } from '@angular/core';
import { Store } from "@ngrx/store";
import { selectUser } from "../../../stores/auth/auth.selector";
import { Router } from "@angular/router";
import { formIsVisible, getChatIdSelected, getChatsSideBar } from "../../../stores/chatbot/chatbot.selector";
import { renameChatTitle, requestChat, requestChats, requestChatsSideBar, resetStatus } from "../../../stores/chatbot/chatbot.actions";
import { EPaths } from '../../enums/path.enums';
import { ERole } from '../../enums/role.enums';
import { IChat, IChatForSidebar, IChatPatameters } from 'src/app/interfaces/IChat';
import { RelativeDatePipe } from '../../pipes/relative-date.pipe';
import { EMPTY, fromEvent, map, noop, Observable, switchMap, take, tap } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { getInstancesList, getInstancesSelected } from 'src/app/stores/instances/instances.selector';
import { logout } from 'src/app/stores/auth/auth.actions';
import { requestInstancesList, setParametersSelected, updateModel } from 'src/app/stores/instances/instances.actions';
import { Option } from "src/app/@core/interfaces/generic.interfaces";
import { AlertService } from 'src/app/@shared/alert/alert.service';
import { MdbSidenavComponent } from 'mdb-angular-ui-kit/sidenav';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ParametersModalComponent } from 'src/app/@shared/modal/parameters-modal/parameters-modal.component';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  providers: [RelativeDatePipe]
})
export class SidebarComponent implements OnInit, AfterViewInit  {
  @ViewChild('sidenav', { static: true }) sidenav!: MdbSidenavComponent;
  user$ = this.store.select(selectUser)
  type: string | null = null;
  showExpand: boolean;
  paths = EPaths;
  roleEnum = ERole;
  chatSideBar$ = this.store.select(getChatsSideBar)
  valid: Observable<boolean> = this.store.select(formIsVisible)
  error: any = "";
  instanceOptions: Option[];
  parameters: Observable<IChatPatameters | null>;
  index: Observable<string>;
  mode = window.innerWidth >= 576 ? 'side' : 'over';
  hidden = window.innerWidth >= 576 ? false : true;

  formUpdateModel = new FormGroup({
    model: new FormControl("", Validators.required)
  })
  modalRef: MdbModalRef<ParametersModalComponent> | null = null;



  constructor(
    private store: Store,
    private router: Router,
    private alert: AlertService,
    private ngZone: NgZone,
    private modalService: MdbModalService
  ) {

    this.store.dispatch(requestChatsSideBar())
  }


  ngOnInit(): void {
    this.formUpdateModel.reset()
    this.index = this.store.select(getChatIdSelected)
    this.store.dispatch(requestInstancesList())
    this.store.select(getInstancesList).pipe(
      tap((data) => {
        if (data) {
          this.instanceOptions = data!.data ?? []
        }
      })
    ).subscribe(noop)
    this.parameters = this.store.select(getInstancesSelected).pipe(

      tap(res => {
        this.formUpdateModel.patchValue({
          model: res?.model,
        })
      })
    )



  }


  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(window, 'resize').pipe(
        // Riduci il numero di eventi da gestire con debounceTime
        map(() => window.innerWidth),
        tap((width) => {
          if (width < 576 && this.mode !== 'over') {
            this.ngZone.run(() => {
              this.mode = 'over';
              this.hideSidenav();
              this.openParameters(); 
            });
          } else if (width >= 576 && this.mode !== 'side') {
            this.ngZone.run(() => {
              this.mode = 'side';
              this.showSidenav();
            });
          }
  
          // Chiudi il modale se la larghezza supera 576px
          if (width >= 576 && this.modalRef) {
            this.ngZone.run(() => {
              this.modalRef!.close();
            });
          }
        })
      ).subscribe();
    });
  }
  

  hideSidenav() {
    setTimeout(() => {
      this.sidenav.hide();
    }, 0);
  }

  showSidenav() {
    setTimeout(() => {
      this.sidenav.show();
    });
  }

  openParameters() {
    this.modalRef = this.modalService.open(ParametersModalComponent)
  }

  modelChange() {
    this.store.select(getInstancesSelected).pipe(
      take(1),
      switchMap(inst => {
        if (inst && inst.model !== this.formUpdateModel.value.model) {
          return this.store.select(getChatIdSelected).pipe(
            take(1),
            tap(chatId => {
              if (chatId && chatId !== "new" ) {
                this.store.dispatch(updateModel({
                  chatId: chatId,
                  model: this.formUpdateModel.value.model!
                }));
              }
              else if (chatId && chatId === "new") {
                    this.store.dispatch(setParametersSelected({ instance:inst.instance, model: this.formUpdateModel!.value.model!}))
              }
            })
          );
        }
        return EMPTY;
      })
    ).subscribe(() => {
      this.store.dispatch(requestChatsSideBar());
    });
  }

  /*   onSidenavHidden() {
      http://localhost:5575/dashboard/colossus/chat/66fbd4e9b51d0318000f9d93
      this.showExpand = true;
    }
  
    onSidenavShow() {
      this.showExpand = false;
    } */

  startEditing(chat: IChatForSidebar) {
    chat.isEditing = true;
    chat.newName = chat.title;
    setTimeout(() => {
      const input = document.getElementById('edit-input-' + chat._id) as HTMLInputElement;
      input?.focus();
    });
  }

  onInputChange(event: Event, chat: IChatForSidebar) {
    const target = event.target as HTMLInputElement;
    if (target) {
      chat.newName = target.value;
    }
  }

  reset(chat: IChatForSidebar) {
    this.store.dispatch(resetStatus({ chatId: chat._id ? chat._id : 'new' }))
  }

  finishEditing(chat: IChatForSidebar) {
    if (chat.newName && chat.newName.trim() !== '') {
      this.store.dispatch(renameChatTitle({ chatId: chat._id, title: chat.newName.trim() }))
    }
    chat.isEditing = false;
  }

  cancelEditing(chat: IChatForSidebar) {
    chat.isEditing = false;
  }

  logout() {
    localStorage.removeItem('token')
    this.router.navigate(['/login'])
  }
}
