import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatComponentService {

  constructor() { }
  inputIstance: string;
  inputIa: string;
/*   valid = new BehaviorSubject<boolean>(true)


  getValue() {
    return this.valid.asObservable();
  }


  hideForm(value: boolean) {
    this.valid.next(value)
  } */
}