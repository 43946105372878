import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {authGuard} from "./@core/guards/auth.guard";
import { ConfirmPasswordComponent } from './features/confirm-password/confirm-password.component';
import { ResetPasswprdComponent } from './@core/components/reset-passwprd/reset-passwprd.component';

const routes: Routes = [
  {path: '', redirectTo:'login', pathMatch: 'full'},
  {path: 'login', loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),canActivate: [ authGuard]},
  {path: 'dashboard', loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [authGuard]},
  {path: 'reset-password/:token', component:ConfirmPasswordComponent},
  {path: 'send-email', component:ResetPasswprdComponent},
  {path: 'complete-registration/:token', component:ConfirmPasswordComponent},
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
