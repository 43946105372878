<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalRef.close()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="   p-0" >
        <div class="card-body py-0 ">
          <h5 class="card-title m-0 mb-1">Chat Settings</h5>
          <h6 class="card-subtitle mb-1 fw-bold">AI Model</h6>
          <form [formGroup]="formUpdateModel">
            <div id="checkbox-group" class="w-100 p-6 d-flex flex-column justify-content-center bg-light">
              <div mdbRadio class="form-check">
                <input
                  mdbRadio
                  type="radio"
                  id="gpt-4o-2024-08-06"
                  formControlName="model"
                  value="gpt-4o-2024-08-06"
                  class="form-check-input"
                  (change)="modelChange()"
                />
                <label class="form-check-label" for="gpt-4o-2024-08-06">
                  GPT 4o-2024-08-06
                </label>
              </div>
              <div mdbRadio class="form-check">
                <input
                  mdbRadio
                  type="radio"
                  id="gpt-4o-mini"
                  formControlName="model"
                  value="gpt-4o-mini"
                  class="form-check-input"
                  (change)="modelChange()"
                />
                <label class="form-check-label" for="gpt-4o-mini">
                  gpt-4o-mini
                </label>
              </div>
            </div>
          </form>

          <div >
            <h6 class="card-subtitle my-0 mt-1 fw-bolder">Instance</h6>
            <span>{{ (parameters | async)?.instance }}</span>
          </div>
        </div>                
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modalRef.close()">
      Done
    </button>
  </div>