import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ILogin } from "./interfaces/api-auth.interface";
import { Observable } from "rxjs";
import { IResponseApi } from "../../../interfaces/IApi";
import { environment } from "../../../../environments/environment";
import { IgetChatbot, IresendChatbot, IsendChatbot } from "./interfaces/api-chatbot.interface";
import { IChat,  IChatForSidebar, IResponseChat } from "../../../interfaces/IChat";
import { ChatComponentService } from 'src/app/features/dashboard/chat/chat.component.service';

@Injectable({
  providedIn: 'root'
})
export class ApiChatbotService {
  headers = new HttpHeaders()
  constructor(private http: HttpClient, private chatService: ChatComponentService) {
  }


  send(data: IsendChatbot): Observable<IResponseApi<IResponseChat>> {
    let Newdata = data as any;
    if (data.chatId === 'new') {
      delete Newdata.chatId
    }
    return this.http.post<IResponseApi<any>>(environment.url_api + '/chatbot/send', Newdata, { headers: this.headers })
  }

  get(data: IgetChatbot): Observable<IResponseApi<IChat>> {
    return this.http.get<IResponseApi<any>>(environment.url_api + '/chatbot/' + data.chatId, { headers: this.headers, params: { chatId: data.chatId } })
  }

/* 
  getChats(): Observable<IResponseApi<IChat[]>> {
    return this.http.get<IResponseApi<any>>(environment.url_api + '/chatbot', { headers: this.headers })
  } */

  getChatsSideBar(): Observable<IResponseApi<IChatForSidebar[]>> {
    return this.http.get<IResponseApi<any>>(environment.url_api + '/chatbot/sidebar', { headers: this.headers })
  }

  renameChatTitle(chatId: string, title: string): Observable<any> {
    return this.http.patch<any>(environment.url_api + '/chatbot/title', { chatId: chatId, title: title }, { headers: this.headers })
  }

  updateModel(chatId: string, model: string): Observable<any> {
    return this.http.patch<any>(environment.url_api + '/chatbot/model', { chatId: chatId, model: model }, { headers: this.headers })
  }

  resendChat(data: IsendChatbot): Observable<IResponseApi<IResponseChat>> {
    return this.http.post<IResponseApi<any>>(environment.url_api + '/chatbot/resend', data, { headers: this.headers })
  }
}