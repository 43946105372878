import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { Mdb5Module } from "./modules/mdb5.module";
import { HttpClientModule } from "@angular/common/http";
import { AlertComponent } from './components/alert/alert.component';
import { LoadingBtnDirective } from "./directives/loading-btn.directive";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { truncatePipe } from "./pipes/truncate.pipe";
import { RoleDirective } from './directives/role.directive';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { StoreModule } from '@ngrx/store';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ResetPasswprdComponent } from './components/reset-passwprd/reset-passwprd.component';

const directives = [LoadingBtnDirective, RoleDirective]
const components = [SidebarComponent, AlertComponent,

]
const pipes = [truncatePipe, RelativeDatePipe]

@NgModule({
    declarations: [
        ...directives,
        ...components,
        ...pipes,
        
    ],
    imports: [
        CommonModule,
        Mdb5Module,
        HttpClientModule,
        RouterLink,
        RouterLinkActive,
        RouterLinkActive,
        ReactiveFormsModule,
    ],
    exports: [
        ...directives,
        ...components,
        ...pipes
    ]
})
export class CoreModule {
}
