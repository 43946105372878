import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as ChatbotActions from './chatbot.actions';

import { ApiChatbotService } from "../../@core/services/api/api-chatbot.service";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { of, tap } from "rxjs";
import { Store } from "@ngrx/store";
import { getChatIdSelected } from "./chatbot.selector";
import { getInstancesSelected } from "../instances/instances.selector";

@Injectable()
export class ChatbotEffects {
    send$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.send),
        tap((action) => {
            this.store.dispatch(ChatbotActions.sendUserMessage({ message: action.query, sender: 'user' }))
            setTimeout(() => {
                this.store.dispatch(ChatbotActions.sendUserMessage({ message: '...', sender: 'bot' }))
            }, 500)
        }),
        mergeMap((action) => this.chatbotservice.send({ instance: action.instance, query: action.query, chatId: action.chatId, model: action.model })
            .pipe(
                map(response => {
                    if (action.chatId === 'new') {
                        this.store.dispatch(ChatbotActions.requestChat({ chatId: response.data.chatId }))
                    }
                    return ChatbotActions.sendSuccess({ chat: response.data })
                }),
            catchError(error => of(ChatbotActions.sendFailure({ error: error })))
            )
        )
    ));
    get$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.requestChat),
        mergeMap((action) => this.chatbotservice.get({ chatId: action.chatId! })
            .pipe(
                map(response => {
                    return ChatbotActions.requestChatSuccess({ chat: response.data })

                }),
                catchError(error => of(ChatbotActions.requestChatFailure({ error: error.error })))
            )
        )
    ));

    getChatsSideBar$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.requestChatsSideBar),
        mergeMap(() => this.chatbotservice.getChatsSideBar()
            .pipe(
                map(response => {
                    return ChatbotActions.requestChatsSideBarSuccess({ chatsSideBar: response.data })
                }),
                catchError(error => of(ChatbotActions.requestChatsSideBarFailure({ error: error.error })))
            )
        )
    ));


    renameChatTitle$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.renameChatTitle),
        mergeMap((action) => this.chatbotservice.renameChatTitle(action.chatId, action.title)
            .pipe(
                map(response => {
                    return ChatbotActions.renameChatTitleSuccess({ chatId: action.chatId, title: action.title })
                }),
                catchError(error => of(ChatbotActions.renameChatTitleFailure({ error: error })))
            )
        )
    ));

    resendMessage$ = createEffect(() => this.actions$.pipe(
        ofType(ChatbotActions.resendChat),
        withLatestFrom(
            this.store.select(getChatIdSelected),
            this.store.select(getInstancesSelected),
        ),
        tap(([action, chatId, instance]) => {
            this.store.dispatch(ChatbotActions.resendUserMessage())
        }),
        mergeMap(([action, chatId, instance]) => this.chatbotservice.resendChat({
            query: action.message, chatId: chatId, instance: instance?.instance!, model: instance?.model!
        })
            .pipe(map(response => {
                return ChatbotActions.resendSuccess({ chat: response.data })
            }),
                catchError(error => of(ChatbotActions.sendFailure({ error: error.error })))
            )
        )
    ));
    constructor(private actions$: Actions, private chatbotservice: ApiChatbotService, private store: Store) {
    }
}
