<mdb-sidenav-layout>
  <mdb-sidenav #sidenav="mdbSidenav" [accordion]="false" [color]="''" [hidden]="false" [mode]="'side'"
    class="dashboard-sidenav__wrapper" >
    <div class="dashboard-sidenav__wrapper">
      <div class="img-box row justify-content-center align-items-center">
        <div class=" col-8 d-flex justify-content-start">
          <img src="../../../../assets/logo.svg" class="w-100 h100" alt="logo">
        </div>
      </div>
      <hr class="m-0" />

      <ul class="sidenav-menu dashboard-sidenav__wrapper__menu">
        <ng-container>
          <mdb-sidenav-item>
            <a class="sidenav-link" routerLink="colossus/chat/new" routerLinkActive="active">
              <i class="far fa-comments me-3 text-light "></i>
              <span class="text-light NewBtn"><strong>New chat</strong></span>
            </a>
          </mdb-sidenav-item>


            <ng-container *ngFor="let chat of (chatSideBar$ | async) as chats;  let i = index">
              <div *ngIf="i === 0 || chat.dateKey !== chats[i-1].dateKey" class="font-weight-bold  d-flex ms-4 mt-3 align-items-center text-light chatTitle">{{chat.dateKey}}</div>
              <mdb-sidenav-item>
                <a class="sidenav-link text-light " [routerLink]="'colossus/chat/'+ chat._id" routerLinkActive="active"
                  (dblclick)="startEditing(chat)">
                  <span *ngIf="!chat.isEditing" class="chatMessage ">
                    {{chat.newName ? chat.newName : chat.title}}
                  </span>
                  <input mdbInput type="text" *ngIf="chat.isEditing" [value]="chat.newName!"
                    (input)="onInputChange($event, chat)" (blur)="finishEditing(chat)" (keyup.enter)="finishEditing(chat)"
                    (keyup.escape)="cancelEditing(chat)" [id]="'edit-input-' + chat._id" class="form-control" />
                </a>
              </mdb-sidenav-item>
            </ng-container> 
        </ng-container>
      </ul>
    </div>
  </mdb-sidenav>
  <mdb-sidenav-content #sidenavContent>

    <!-- Navbar-->
    <nav class="cb-navbar navbar navbar-expand-lg navbar-light bg-light p-0">
      <div class="container-fluid justify-content-between">
        <!-- Left elements -->
        <div class="d-flex">
              <div class="row my-3"   *ngIf="(parameters| async)?.instance " >
                <div class="col-12  h-100 d-flex align-items-center">
                  <div  class=" "><strong class="text-bold">AI Model:</strong></div>
                  <div class="mx-4">
                    <form [formGroup]="formUpdateModel">
                      <mdb-form-control class="my-4">
                        <mdb-select formControlName="model">
                          <mdb-option (click)="modelChange()" value='gpt-4o-2024-08-06'>
                            <div>
                               GPT 4o-2024-08-06
                            </div>
                          </mdb-option>
              
                          <mdb-option (click)="modelChange()" value='gpt-4o-mini'>
                            <div>
                               GPT 4o-mini
                            </div>
                          </mdb-option>
              
                        </mdb-select>
                      </mdb-form-control>
                    </form>
            
                  </div>
                  <div  class=" mx-4"><strong class="text-bold">Instance:</strong><span  class=" mx-2">{{(parameters| async)?.instance}}</span></div>
                </div>
              </div>
        </div>
        <!-- Left elements -->

        <!-- Right elements -->
        <ul class="navbar-nav flex-row">
          <li *roles="[roleEnum.ADMIN]" class="nav-item me-3 me-lg-1">
            <a class="nav-link" [routerLink]='paths.MANAGEMENT' routerLinkActive="active" href="#">
              <i class="fas fa-users-gear fa-lg"></i>
            </a>
          </li>
          <li class="nav-item me-3 me-lg-1">
            <a class="nav-link" [routerLink]='paths.MY_PROFILE' routerLinkActive="active" href="#">
              <i class="fas fa-user fa-lg"></i>
            </a>
          </li>
          <li class="nav-item me-3 me-lg-1">
            <a class="nav-link" href="#" (click)="logout()">
              <span><i class="fas fa-sign-out-alt fa-lg"></i></span>
            </a>
          </li>
        </ul>
        <!-- Right elements -->
      </div>
    </nav>
    <!-- Navbar -->

    <div class="container">
      <ng-content></ng-content>
    </div>

  </mdb-sidenav-content>
</mdb-sidenav-layout>